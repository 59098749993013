#droite {
  right: 0%;
}

#gauche {
  left: 0%;
}

#droite img {
  z-index: 2;
  height: 100%;
  width: 175px;
  margin-left: 13px;
  margin-top: -3px;
}

#gauche img {
  z-index: 2;
  height: 100%;
  width: 175px;
  margin-left: 13px;
  margin-top: -3px;
}

#rond-droite {
  right: calc(50% - 100px);
  position: fixed;
  z-index: 4;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  overflow: hidden;
  -webkit-border-radius: 250px;
  -moz-border-radius: 250px;
  border-radius: 250px;
  border: 2px solid #fff;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background: none;
}

#rond-droite:hover {
  background: rgba(0, 0, 0, 1);
  border: 2px solid #000;
}

#rond-gauche {
  display:none;
  left: 100px;
  position: fixed;
  z-index: 4;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  overflow: hidden;
  -webkit-border-radius: 250px;
  -moz-border-radius: 250px;
  border-radius: 250px;
  border: 2px solid #fff;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background: none;
}

#rond-gauche:hover {
  background: rgba(0, 0, 0, 1);
  border: 2px solid #000;
}