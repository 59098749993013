
// @import url('http://fonts.googleapis.com/css?family=Varela+Round');
// @import url('http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700,400italic,700italic');
// @import url('http://fonts.googleapis.com/css?family=Montserrat:700');

@font-face {
	font-family: "bau";
	src: url("fonts/bau-regular.eot");
	src: url("fonts/bau-regular.eot#iefix") format("embedded-opentype"),
	url("fonts/bau-regular.woff") format("woff"),
	url("fonts/bau-regular.ttf") format("truetype"),
	url("fonts/bau-regular.svg") format("svg");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "bau";
	src: url("/assetsfonts/bau-medium.eot");
	src: url("/assets/fonts/bau-medium.eot#iefix") format("embedded-opentype"),
	url("/assets/fonts/bau-medium.woff") format("woff"),
	url("/assets/fonts/bau-medium.ttf") format("truetype"),
	url("/assets/fonts/bau-medium.svg") format("svg");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "bau";
	src: url("/assets/fonts/bau-bold.eot");
	src: url("/assets/fonts/bau-bold.eot#iefix") format("embedded-opentype"),
	url("/assets/fonts/bau-bold.woff") format("woff"),
	url("/assets/fonts/bau-bold.ttf") format("truetype"),
	url("/assets/fonts/bau-bold.svg") format("svg");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "bau";
	src: url("/assets/fonts/bau-super.eot");
	src: url("/assets/fonts/bau-super.eot#iefix") format("embedded-opentype"),
	url("/assets/fonts/bau-super.woff") format("woff"),
	url("/assets/fonts/bau-super.ttf") format("truetype"),
	url("/assets/fonts/bau-super.svg") format("svg");
	font-weight: 600;
	font-style: normal;
}