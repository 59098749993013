.project {
	margin-bottom: 40px;
	position: relative;
	overflow: hidden;
}

#savoir-flechehaut {
	width: 60px;
	cursor: pointer;
	bottom: 17px;
	left: calc(50% - 30px);
	position: fixed;
	z-index: 4;
	opacity: 1;
}

#savoir-fleche1 {
	width: 60px;
	cursor: pointer;
	bottom: 20px;
	left: calc(50% - 30px);
	position: fixed;
	z-index: 4;
	opacity: 0.9;
}

#savoir-fleche1:hover {
	opacity: 1;
}

#topOffest {
	width: 100%; 
	height: 200px;
}

@media (max-width: 640px) {
	#savoir-fleche1 {
		display: none;
	}
	#savoir-flechehaut{
		display: none;
	}
	#topOffest {
		width: 100%; 
		height: 100px;
	}
}

.projectThumbnail {
	max-width: 100%;
	height: 100%;
	cursor: pointer;
}

.projectThumbnail img {
	display: block;
}

.thumbnailMask {
	pointer-events: none;
	width: 460px;
	height: 284px;
	position: absolute;
	z-index: 24;
}

.projectThumbnailHover {
	background-image: url("../images/masks/rond.png");
	position: absolute;
	width: 100%;
	float: center;
	height: 100%;
	display: none;
	z-index: 2;
	color: white; 
}

@media (max-width: 640px) {
	.projectThumbnailHover {
		display: block;
	}
}

.projectThumbnailHover h4 {
	font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: -0.05em;
	position: absolute;
	display: none;
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	line-height: 1;
	text-align: center;
	letter-spacing: 0;
	margin-top: 40px;
	left: 465px;
}

.projectThumbnailHover h5, .projectThumbnailHover h6 {
	position: absolute;
	display: none;
	font-weight: 600;
	font-style: normal;
	font-size: 11px;
	text-align: center;
	line-height: 1.2;
	letter-spacing: 0.3em;
	padding-top: 102px;
}

.thumbnailImage {
	width: 940px;
}

@media (max-width: 640px) {
	.thumbnailImage{
		width: 100%;
	}
}