.morceau-groupe {
    position: fixed;
    left: calc(50% - 200px);
    z-index: 4;
    width: 400px;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-style: normal;
    font-size: 11px;
    line-height: 1.2;
    letter-spacing: 0.3em;
}

.morceau-groupe img {
    width: 100%;
}

@media (max-width: 640px) {
	.morceau-groupe {
        display: none;
    }
}

.morceau-groupe p {
    position: fixed;
    left: calc(50% - 200px);
    z-index: 4;
    width: 400px;
    text-align: center;
    margin-top: 10px;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-style: normal;
    font-size: 11px;
    line-height: 1.2;
    letter-spacing: 0.3em;
}

.video {
    position: fixed;
    top: 0%;
    width: 100%;
    height: 100%;
    background: black;
    border-bottom: 1px solid #fff;
    z-index: 3;
    -moz-box-shadow: 0px 5px 40px 0px #000000;
    -webkit-box-shadow: 0px 5px 40px 0px #000000;
    -o-box-shadow: 0px 5px 40px 0px #000000;
    box-shadow: 0px 5px 40px 0px #000000;
    filter: progid:DXImageTransform.Microsoft.Shadow(color=#000000, Direction=180, Strength=20);
}

#hidevimeo {
    cursor: url('assets/images/bas2.png') 30 30, pointer;
    pointer-events: none;
}

#plus-info {
    width: 190px;
    height: 70px;
    bottom: 0px;
    left: calc(50% - 95px);
    position: absolute;
    z-index: 4;
    opacity: 1;
    cursor: pointer;
    pointer-events: initial;
}

.videoWrapper {
    top: 0%;
    width: 100%;
    height: 100%;
    background: #000;
}

.videoWrapper iframe {
    top: 0;
    left: 200px;
    position: inherit;
}

.imageWrapper {
    position: fixed;
    width: 50%;
}

.textWrapper {
    position: fixed;
    opacity: 0;
}

.textWrapper h2 {
    line-height: 1.8em;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
    letter-spacing: 0.3em;
    padding-bottom: 30px;
    padding-right: 10px;
}

.column1 {
    position: fixed;
    top: 15%;
    left: 76%;
    width: 17%;
    word-break: normal;
    font-size: 0.8em;
    color: #acacac;
}

.column1-eng {
    position: fixed;
    width: 17%;
    left: 54%;
    top: 15%;
    font-size: 0.8em;
}

.images img {
    overflow: hidden;
    width: 100%;
    margin: 10;
}