.message{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(65%);
    text-align: center;
}

.quinzequinze{
	position: absolute;
	left: 50%;
    transform: translateX(-50%);
    top: calc(90%);
}
