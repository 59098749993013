nav {
  z-index: 4;
  position: fixed;
}

body {
  margin: 0;
  padding: 0;
  color: white;
  font-family: "bau", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  text-transform: none;
  text-rendering: auto;
}

.boutons {
  -webkit-transition: fill 0.4s ease-out;
  -moz-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
  fill: #4a4a4a;
  position: fixed;
  z-index: 7;
}

.boutons:hover {
  fill: #ccc;
}

// .boutons-social {
//   -webkit-transition: fill 0.4s ease-out;
//   -moz-transition: fill 0.4s ease-out;
//   -o-transition: fill 0.4s ease-out;
//   transition: fill 0.4s ease-out;
//   fill: #ccc;
//   position: relative;
//   z-index: 2;
// }

// .boutons-social:hover {
//   fill: #4a4a4a;
//   opacity:0.5;
// }

#lien-interactif {
  position: fixed;
  width: 150px;
  top: 30px;
  left: 30px;
}

#lien-musique {
  position: fixed;
  width: 175px;
  top: 35px;
  left: 30px;
}

#newsletter {
  position: fixed;
  width: 150px;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

#newsletter:hover {
  position: fixed;

}

#contact-us {
  position: fixed;
  width: 125px;
  bottom: 30px;
  left: 35px;
  cursor: pointer;
}

#contact-us:hover {
  position: fixed;
  width: 125px;
  bottom: 30px;
}

#about-us {
  position: fixed;
  width: 100px;
  bottom: 30px;
  right: 45px;
}

#logo-quinzequinze{
	width: 150px;
	left: calc(50% - 75px);
	top: 31px;
	fill: rgb(255, 255, 255);
}

#burger{
	width: 150px;
	left: 30px;
	top: 31px;
	fill: rgb(255, 255, 255);
}

#close-about-mobile{
  width: 10px;
	right: 30px;
	top: 10px;
	fill: rgb(255, 255, 255);
}


#contact {
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
}

.slider-button .button-text {
  color: white;
  font-family: 'arial black', sans-serif;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: all 0.65s;
  -moz-transition: all 0.65s;
  transition: all 0.65s;
}

.slider-button, .text-slider .btn {
  padding: 8px 20px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  position: relative;
  display: inline-block;
  z-index: 5;
  width: 300px;
  height: 105px;
  left: calc(50% - 150px);
  top: calc(50% - 75px);
  text-align: center;
  outline: none;
}

.slider-button:after, .text-slider .btn:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  content: '';
  line-height: 4em;
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.slider-button:hover, .slider-button:active, .text-slider .btn:hover, .text-slider .btn:active {
  border-color: #fff;
  color: rgba(0, 0, 0, 0.7);
}

.slider-button:hover .button-text, .slider-button:active .button-text {
  color: rgba(0, 0, 0, 0.7);
}

.slider-button:hover:after, .slider-button:active:after, .text-slider .btn:hover:after, .text-slider .btn:active:after {
  width: 100%;
}

#about {
  position: fixed;
  z-index: 5;
  width: 100%;
  top: 0;
  left: 0%;
  height: 100%;
  display: none;
  overflow: auto;
  background: #FFF
}

#about-content {
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-bottom: 400px;
  position: fixed;
  overflow: auto;
  height: 2000px;
  background: #000;
}
#about-mobile{
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0%;
  height: 100%;
  display: none;
  overflow: auto;
  background: rgb(0, 0, 0);
}


#about-mobile a {

  text-decoration: none;
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 60px;
  font-weight: 500;
  left: 30px;
  position: relative;
  color: white;

}

#about-mobile div {
  padding-bottom: 16px;
}


#aboutclose {
  cursor: pointer;
  position: fixed;
  top: 27px;
  right: calc(50% - 13px);
  width: 26px;
  z-index: 99999;
}




.about-rond {
  width: 35%;
  left: calc(50% - 17.5%);
  position: absolute;
  z-index: 4;
  vertical-align: middle;
  overflow: hidden;
  opacity: 1;
  color: #000;
}

.rond {
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  border: 4px solid #fff;
  width: 35%;
}

#texte-experience {
  width: 35%;
  margin-left: 10%;
}

#social-right {
  top: calc(50% - 52px);
  right: 75px;
  width: 30px;
  height: 90px;
  position: fixed;
  z-index: 4;
  display: inline;
}

#shareTexte {
  bottom: 70px;
  left: calc(50% - 66px);
  position: fixed;
  color: #fff;
  width: 124px;
  margin-left: 3px;
  letter-spacing: 0.3em;
  font-size: 9px;
  text-align: center;
  display: none;
  cursor: help;
  font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.boutons-social {
  -webkit-transition: fill 0.4s ease-out;
  -moz-transition: fill 0.4s ease-out;
  -o-transition: fill 0.4s ease-out;
  transition: fill 0.4s ease-out;
  fill: #ccc;
  position: relative;
  z-index: 2;
}

.boutons-social:hover {
  // fill: #4a4a4a;
  opacity:0.5;
}

#facebook, #twitter, #instagram, #stumble, #spotify, #deezer, #itunes {
  height: 100%;
  margin-top: -10px;
}

#listen-left {
  top: calc(50% - 52px);
  left: 75px;
  width: 30px;
  height: 90px;
  position: fixed;
  z-index: 4;
  display: inline;
}

@media screen and (max-width:840px) {
  #listen-left {
    left: 35px;
    top: calc(80% - 52px);
  }

  #social-right {
    top: calc(80% - 52px);
    right: 35px;
  }
  nav{
    z-index: 5;
  }

}

#home {
  left: calc(50% - 4px);
  position: fixed;
  z-index: 4;
  height: 8px;
  width: 8px;
  top: 35px;
  overflow: hidden;
  -webkit-border-radius: 250px;
  -moz-border-radius: 250px;
  border-radius: 250px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  background: white;
}

@media (max-width: 640px) {
	#home {
		display: none;
	}
}

#home:hover {
  height: 14px;
  width: 14px;
  left: calc(50% - 7px);
  top: 31px;
}



#previous:hover {
  cursor: pointer;
  opacity: 0.7;
}




@media (min-width: 640px) {
	#next {
    text-decoration: none;
    color: white;
    position: fixed;
    width: 150px;
    height: 10px;
    right: -30px;
    top: calc(50% - 5px);
    content: 'yo';
    opacity: 0.4;
    z-index: 4;
    letter-spacing: 0.4em;
    font-size: 10.5px;
    text-align: center;
    font-weight: bold;
    -ms-transform: rotate(-90deg); /* IE 9 */
    -webkit-transform: rotate(-90deg); /* Chrome, Safari, Opera */
    transform: rotate(-90deg);
    font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
  }

  #previous {
    text-decoration: none;
    color: white;
    position: fixed;
    width: 200px;
    height: 10px;
    left: -50px;
    top: calc(50% - 5px);
    opacity: 0.4;
    z-index: 4;
    letter-spacing: 0.4em;
    font-size: 10.5px;
    text-align: center;
    font-weight: bold;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -webkit-transition: opacity 0.2s ease-out;
    -moz-transition: opacity 0.2s ease-out;
    -o-transition: opacity 0.2s ease-out;
    transition: opacity 0.2s ease-out;
  }
}

@media (max-width: 640px) {
	#next {
    text-decoration: none;
    color: white;
    position: fixed;
    width:  15px;
    height: 10px;
    right: 55px;
    top: 90px;
    font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  #previous {
    text-decoration: none;
    color: white;
    position: fixed;
    width: 15px;
    height: 10px;
    left: 30px;
    top: 90px;
    font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

#next:hover {
  cursor: pointer;
  opacity: 1;
}