@import 'css/partials/_movie';
@import 'css/partials/mobile';
@import 'css/partials/home-experience';
@import 'css/partials/project-experience';
@import 'css/partials/home-music';
@import 'css/partials/project-music';
@import 'css/partials/navigation';
@import 'css/partials/reset';
@import 'css/partials/fonts';
@import 'css/partials/home';

*, *:before, *:after {
	box-sizing: inherit;
}
button{
	z-index:9999;
}
body {
	background-color: black;
}

iframe {
	z-index: 0;
	position: fixed;
	// left: 0;
	// top: 0;
	width: 100%;
}


@media (max-width: 640px) {
	iframe {
		z-index: 0;
		position: fixed;
		// left: 0;
		// top: 0;
		width: 100% !important;
		top: 50%;
  		margin-top: -50%;
		height: 100%;
	}
}

@media (min-width: 640px) {
	iframe {
		height: 100%;
	}
}

.container {
	position: relative;
	width: 960px;
	margin: 0 auto;
	padding: 0;
}

@media (max-width: 640px) {
	.container{
		width: 100%;
	}
}


.container .column, .container .columns {
	float: left;
	display: inline;
	margin-left: 10px;
	margin-right: 10px;
}

#load {
	position: absolute;
	top: 1%;
	left: 50%;
	background: url(../images/load2.gif) center center;
	z-index: 80;
	font-size: 5em;
	line-height: 1;
	width: 20px;
	height: 20px;
	margin-left: -15px;
}

#load2 {
	position: absolute;
	bottom: 1%;
	left: 50%;
	background: url(../images/load2.gif) center center;
	z-index: 80;
	font-size: 5em;
	line-height: 1;
	width: 30px;
	height: 30px;
	margin-left: -15px;
}

#load3 {
	position: absolute;
	top: 50%;
	left: 1%;
	background: url(../images/load3.gif) center center;
	z-index: 80;
	font-size: 5em;
	line-height: 1;
	width: 30px;
	height: 30px;
	margin-top: -15px;
}

#load4 {
	position: absolute;
	top: 50%;
	right: 1%;
	background: url(../images/load3.gif) center center;
	z-index: 80;
	font-size: 5em;
	line-height: 1;
	width: 30px;
	height: 30px;
	margin-top: -15px;
}

#load5 {
	background: url(../images/loading.gif);
	top: 50%;
	position: absolute;
	transform: translate3d(-50%, -50%, 0);
	left: 50%;
}

#sitePreloader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000;
	z-index: 2;
}