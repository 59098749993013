*, *:before, *:after {
    box-sizing: inherit;
    
}

.player {
    width: 100%;
    position: relative;
    font-size: 0;
    padding: 0;
    margin: 0;
    background-color: red;
}


.player__video {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.player[data-fullscreen=true] {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    height: 100%;
    max-height: 100%;
    background-color: black;
}

.player[data-fullscreen=true] video {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
    background-color: black;
}
.player[data-fullscreen=true] progress{
    display: none;
}

.player[data-fullscreen=true] .player__controls{
    z-index: 2147483648;
}



.player__controls {
    font-size: 24px;
    font-size: 28px;
    text-align: center;
    // font-family: 'Work Sans', sans-serif;
    color: white;
    cursor: pointer;
}

.player__controls:hover{
    /*border-bottom: none;*/
    color: rgb(200,200,200);
    border-color:rgb(200,200,200);
}

.player__controls-hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;
}

.desaturate {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    /*filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");*/
}


.player__fullscreen {
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: right;
    font-size: 20px;
}

.player__mute {
    position: absolute;
    top: 37px;
    right: 10px;
    text-align: right;
    font-size: 20px;
}

.player__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    cursor: pointer;
    text-decoration: none;
    border-bottom: 2px solid white;
    background-color: transparent;
}

.player__progress  {
    display: none; 

    width: 100%;
    margin: 0 auto;
    border: 0 none;
    padding: 0;
    margin: 0;
    background: lightgrey;
    position: absolute;
    bottom: 14px;
    left: 0;
    height: 7px;
        transition: height 0.3s;

}

.player__progress:hover{
    height: 24px;  
    transition: height 0.3s;
}

.player__progress::-moz-progress-bar {
    background: black;
}

.player__progress::-webkit-progress-bar {
    background: transparent;
}

.player__progress::-webkit-progress-value {
    background: black;
}
