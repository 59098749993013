#xp-texte {
    position: fixed;
    width: 80%;
    z-index: 6;
    height: 80%;
    left: 10%;
    cursor: url('../images/bas2.png') 30 30, pointer;
}

.xp-texte, .xp-texte2 {
    width: 100%;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
    color: #acacac;
    font-size: 2vw;
    position: fixed;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    z-index: 4;
    transition: all 200ms linear;
}

.xp-texte {
    cursor: url('../images/bas2.png') 30 30, pointer;
}

.english {
    color: white;
}

.spritespin {
    margin: 0 auto;
    width: 500px;
}

.niquelesysteme {
    opacity: 0.5;
    width: 500px;
    height: 100%;
    position: absolute;
    z-index: 3;
}

.projet-wrapper {
    width: 500px;
    left: calc(50% - 250px);
    margin-top: 80px;
    position: relative;
}

.projectThumbnailXP {
    width: 500px;
    height: 100%;
    position: absolute;
}

.projectThumbnailXPHover h4 {
        font-family: "Varela Round", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: normal;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        text-align: center;
        letter-spacing: 0;
        text-decoration: none;
        text-transform: none;
        text-rendering: auto;
        position: absolute;
        top: -105px;
    
}

.projectThumbnailXPHover h5 {
        font-weight: 600;
        text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);
        font-style: normal;
        font-size: 11px;
        text-align: left;
        line-height: 1.2;
        letter-spacing: 0.3em;
        text-decoration: none;
        text-transform: none;
        text-rendering: auto;
        position: absolute;
        left: 100px;
        top: -30px;
        width: 200px;
    
}

.projectThumbnailXPHover {
    position: absolute;
    background: green;
    left: 240px;
    width: 200px;
    z-index: 2;
    display: none;
}